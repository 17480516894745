/* eslint-disable arrow-body-style */
import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import appConfig from 'config/app.config';
import { Routes } from 'react-router-dom/dist';
import { FaroRoutes } from '@grafana/faro-react';
import firebaseInit from 'firebase-init';
import { manifestData } from 'manifest';
import { getBrowserLanguage } from 'helpers/language-helper';
import { handleRedirect } from 'helpers/redirect-helper';
import { getOrientation, getAspectRatio, checkIfInStandaloneMode } from 'helpers/device-helper';
import LandingPage from './landing-page/landing-page';
import BookDemo from './book-demo/book-demo';
import './app.scss';

const CpanelAuth = React.lazy(() => import ('./cpanel/auth/cpanel-auth'));
const Company = React.lazy(() => import ('./company/company'));
const ResetPasswordPage = React.lazy(() => import ('./reset-password-page/reset-password-page'));

const App = () => {
	/* Language id */
	const [languageId, setLanguageId] = useState(getBrowserLanguage());

	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {
			isUsingTouch: false,
			isInStandaloneMode: checkIfInStandaloneMode(),
			orientation: getOrientation(),
			aspectRatio: getAspectRatio(),
		};
	});

	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.orientation = getOrientation();
		newDeviceInfo.aspectRatio = getAspectRatio();
		setDeviceInfo(newDeviceInfo);
	};

	/**
	 * User interacted with a touch (first time only)
	 * We check for touch interaction, because we want to
	 * know what is used (not what is available).
	 */
	const handleTouchStart = () => {
		/* Update device info */
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.isUsingTouch = true;
		setDeviceInfo(newDeviceInfo);
		
		/* Remove event listener for touch start */
		if (document.removeEventListener) window.removeEventListener('touchstart', handleTouchStart, false);
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		const currentUrl = window.location.href;
		handleRedirect(currentUrl);
		
		/* Custom manifest - allows to save a path in "save to home screen" */
		const dynamicManifest = JSON.parse(JSON.stringify(manifestData));
		dynamicManifest['start_url'] = currentUrl;
		const stringManifest = JSON.stringify(dynamicManifest);
		const blob = new Blob([stringManifest], { type: 'application/json' });
		const manifestUrl = URL.createObjectURL(blob);
		const manifestElement = document.getElementById('manifest-placeholder');
		if (manifestElement) {
			manifestElement.setAttribute('href', manifestUrl);
		}

		/* Initialize firebase */
		firebaseInit();

		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Add event listener for touch start */
		if (document.addEventListener) window.addEventListener('touchstart', handleTouchStart, false);
		
		/* Component will ummount */
		return () => {
			/* Remove event listeners for window size & touch start */
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
			if (document.removeEventListener) window.removeEventListener('touchstart', handleTouchStart, false);
		};
	});

	const UsedRoute = appConfig.env === 'demo' ? Routes : FaroRoutes;

	return (
		<div id="App" className={'App ' + deviceInfo.orientation}>
			<Router>
				<UsedRoute>
					{/* Control panel - for Copenhagen Game Lab users */}
					<Route 
						path="/cpanel"
						element={
							<Suspense fallback={
								<LandingPage 
									languageId={languageId} deviceInfo={deviceInfo} setLanguageId={setLanguageId}
								/>
							}>
								<CpanelAuth 
									languageId={languageId} 
									deviceInfo={deviceInfo} 
									setLanguageId={setLanguageId}
								/>
							</Suspense>							
						}
					/>
					{/* Company */}
					<Route
						path="/:companyUrl"
						element={
							<Suspense fallback={
								<LandingPage 
									languageId={languageId} deviceInfo={deviceInfo} setLanguageId={setLanguageId}
								/>
							}>
								<Company defaultLanguageId={languageId} deviceInfo={deviceInfo} />
							</Suspense>
						}
					/>
					{/* Company course */}
					<Route
						path="/:companyUrl/:courseUrl"
						element={
							<Suspense fallback={
								<LandingPage 
									languageId={languageId} deviceInfo={deviceInfo} setLanguageId={setLanguageId}
								/>
							}>
								<Company defaultLanguageId={languageId} deviceInfo={deviceInfo} />
							</Suspense>
						}
					/>
					{/* Book Demo Page */}
					<Route
						path="/bookdemo"
						element={<BookDemo
							languageId={languageId}
							deviceInfo={deviceInfo}	
							setLanguageId={setLanguageId}
						/>}
					/>
					{/* Reset password for email-password login */}
					<Route 
						path="/__/auth/:action"
						element={
							<Suspense fallback={
								<LandingPage 
									languageId={languageId} deviceInfo={deviceInfo} setLanguageId={setLanguageId}
								/>
							}>
								<ResetPasswordPage 
									setLanguageId={setLanguageId} 
									languageId={languageId} 
									deviceInfo={deviceInfo}
								/>
							</Suspense>
						}
					/>
					{/* Default landing page */}
					<Route
						path={'/'}
						element={
							<LandingPage 
								languageId={languageId} 
								deviceInfo={deviceInfo}
								setLanguageId={setLanguageId} 
							/>
						}
					/>
				</UsedRoute>
			</Router>
			<div id="preloadedImages" className="App-preloadedImages" />
		</div>
	);
};

export default App;
