const buttonUiTexts = {
	cancel: {
		da: 'Annuller',
		en: 'Cancel',
		se: 'Annullera',
		de: `Abbrechen`,
		pl: `Anuluj`,
		ro: `Anulează`,
		ua: `Скасувати`
	},
	confirm: {
		da: 'Bekræft',
		en: 'Confirm',
		se: 'Bekräfta',
		de: `Bestätigen`,
		pl: `Potwierdź`,
		ro: `Confirmă`,
		ua: `Підтвердити`
	},
	back: {
		da: 'Tilbage',
		en: 'Back',
		se: 'Tillbaka',
		de: `Zurück`,
		pl: `Wstecz`,
		ro: `Înapoi`,
		ua: `Назад`
	},
	next: {
		da: 'Næste',
		en: 'Next',
		se: 'Nästa',
		de: `Weiter`,
		pl: `Następny`,
		ro: `Următorul`,
		ua: `Продовжити`
	},
	ok: {
		da: 'OK',
		en: 'OK',
		se: 'OK',
		de: `OK`,
		pl: `OK`,
		ro: `OK`,
		ua: `OK`
	},
	create: {
		da: 'Opret',
		en: 'Create',
		se: 'Skapa',
		de: `Erstellen`,
		pl: `Utwórz`,
		ro: `Creează`,
		ua: `Створити`
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
		se: 'Välj',
		de: `Wählen`,
		pl: `Wybierz`,
		ro: `Alegeți`,
		ua: `Вибрати`
	},
	finish: {
		da: 'Afslut',
		en: 'Finish',
		se: 'Klar',
		de: `Beenden`,
		pl: `Zakończ`,
		ro: `Finalizați`,
		ua: `Вихід`
	},
	save: {
		da: 'Gem',
		en: 'Save',
		se: 'Spara',
		de: `Speichern`,
		pl: `Zapisz`,
		ro: `Salvează`,
		ua: `Зберегти`
	},
	edit: {
		da: 'Rediger',
		en: 'Edit',
		se: 'Redigera',
		de: `Bearbeiten`,
		pl: `Edytuj`,
		ro: `Editează`,
		ua: `Редагувати`
	},
	delete: {
		da: 'Slet',
		en: 'Delete',
		se: 'Radera',
		de: `Löschen`,
		pl: `Usuń`,
		ro: `Șterge`,
		ua: `Видалити`
	},
	moveAndDelete: {
		da: 'Flyt og slet',
		en: 'Move and delete',
		se: 'Flytta och radera',
		de: `Verschieben und löschen`,
		pl: `Przenieś i usuń`,
		ro: `Mută și șterge`,
		ua: `Перемістити і видалити`
	},
	reset: {
		da: 'Nulstil',
		en: 'Reset',
		se: 'Återställa',
		de: `Zurücksetzen`,
		pl: `Zresetuj`,
		ro: `Resetați`,
		ua: `Скинути`
	},
	remove: {
		da: 'Fjern',
		en: 'Remove',
		se: 'Ta bort',
		de: `Entfernen`,
		pl: `Usuń`,
		ro: `Elimină`,
		ua: `Видалити`
	},
	update: {
		da: 'Updater',
		en: 'Update',
		se: 'Uppdatera',
		de: `Aktualisieren`,
		pl: `Zaktualizuj`,
		ro: `Actualizează`,
		ua: `Оновити`
	},
	copy: {
		da: 'Kopier',
		en: 'Copy',
		se: 'Kopior',
		de: `Kopieren`,
		pl: `Kopiuj`,
		ro: `Copiază`,
		ua: `Копіювати`
	},
	close: {
		da: 'Luk',
		en: 'Close',
		se: 'Stänga',
		de: `Schließen`,
		pl: `Zamknij`,
		ro: `Închide`,
		ua: `Закрити`
	},
	yes: {
		da: 'Ja',
		en: 'Yes',
		se: 'Ja',
		de: `Ja`,
		pl: `Tak`,
		ro: `Da`,
		ua: `Так`
	},
	no: {
		da: 'Nej',
		en: 'No',
		se: 'Nej',
		de: `Nein`,
		pl: `Nie`,
		ro: `Nu`,
		ua: `Немає`
	},
	download: {
		da: 'Download',
		en: 'Download',
		se: 'Ladda ner',
		de: `Herunterladen`,
		pl: `Pobierz`,
		ro: `Descarcă`,
		ua: `Завантажити`
	},
	upload: {
		da: 'Upload',
		en: 'Upload',
		se: 'Ladda upp',
		de: `Hochladen`,
		pl: `Prześlij`,
		ro: `Încarcă`,
		ua: `Завантажити`
	},
	check: {
		da: 'Check',
		en: 'Check',
		se: 'Check',
		de: `Überprüfen`,
		pl: `Sprawdź`,
		ro: `Verifică`,
		ua: `Перевірити`
	}
};

export {
	buttonUiTexts
};