const errorUiTexts = {
	/* Generel errors */
	error: {
		da: 'Fejl',
		en: 'Error',
		se: 'Fel',
		de: 'Fehler',
		pl: 'Błąd',
		ro: 'Eroare',
		ua: 'Помилка',
	},
	unknownError: {
		da: 'Fejl: Ukendt fejl.',
		en: 'Error: Unknown error.',
		se: 'Fel: okänt fel.',
		de: 'Fehler: unbekannter Fehler.',
		pl: 'Błąd: nieznany błąd.',
		ro: 'Eroare: eroare necunoscută.',
		ua: 'Помилка: невідома помилка.',
	},
	unknownServerError: {
		da: 'Fejl. Der skete en ukendt fejl på serveren.',
		en: 'Error. The server returned an unexpected error.',
		se: 'Fel. Servern returnerade ett oväntat fel.',
		de: 'Fehler. Der Server hat einen unerwarteten Fehler zurückgegeben.',
		pl: 'Błąd. Serwer zwrócił nieoczekiwany błąd.',
		ro: 'Eroare. Serverul a returnat o eroare neașteptată.',
		ua: 'Помилка. Сервер повернув неочікувану помилку.',
	},
	serverError: {
		da: 'Der skete en fejl på serveren. Check eventuelt din internetforbindelse og prøv igen.',
		en: 'The server returned an unexpected error. Please check your internet connection and try again.',
		se: 'Servern returnerade ett oväntat fel. Kontrollera din internetanslutning och försök igen.',
		de: 'Der Server hat einen unerwarteten Fehler zurückgegeben. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
		pl: 'Serwer zwrócił nieoczekiwany błąd. Sprawdź połączenie internetowe i spróbuj ponownie.',
		ro: 'Serverul a returnat o eroare neașteptată. Vă rugăm să vă verificați conexiunea la internet și să încercați din nou.',
		ua: 'Сервер повернув неочікувану помилку. Перевірте підключення до Інтернету та повторіть спробу.',
	},

	/* Error page errors */
	couldNotGetUserDataOrRole: {
		da: 'Kunne ikke hente brugerdata.',
		en: 'Could not load user data.',
		se: 'Kunde inte läsa in användardata.',
		de: 'Benutzerdaten konnten nicht geladen werden.',
		pl: 'Nie można załadować danych użytkownika.',
		ro: 'Nu s-au putut încărca datele utilizatorului.',
		ua: 'Не вдалося завантажити дані користувача.',
	},
	couldNotGetCourses: {
		da: 'Kunne ikke hente kursusdata.',
		en: 'Could not load course data.',
		se: 'Det gick inte att läsa in kursdata.',
		de: 'Kursdaten konnten nicht geladen werden.',
		pl: 'Nie można załadować danych kursu.',
		ro: 'Nu s-au putut încărca datele cursului.',
		ua: 'Не вдалося завантажити дані курсу.',
	},
	couldNotDecryptUserInfo: {
		da: 'Kunne ikke læse firmadata.',
		en: 'Could not read company data.',
		se: 'Kunde inte läsa företagsdata.',
		de: 'Unternehmensdaten konnten nicht gelesen werden.',
		pl: 'Nie można odczytać danych firmy.',
		ro: 'Nu s-au putut citi datele companiei.',
		ua: 'Не вдалося прочитати дані компанії.',
	},
	couldNotGetPlayers: {
		da: 'Kunne ikke hente spillerdata.',
		en: 'Could not get player data.',
		se: 'Det gick inte att hämta spelardata.',
		de: 'Spielerdaten konnten nicht abgerufen werden.',
		pl: 'Nie można pobrać danych gracza.',
		ro: 'Nu s-au putut obține datele jucătorului.',
		ua: 'Не вдалося отримати дані гравця.',
	},
	couldNotGetUsers: {
		da: 'Kunne ikke hente facilitator/spiller-data.',
		en: 'Could not get facilitator/player data.',
		se: 'Kunde inte hämta facilitator/spelaredata.',
		de: 'Moderator-/Spielerdaten konnten nicht abgerufen werden.',
		pl: 'Nie można pobrać danych o organizatorze/graczu.',
		ro: 'Nu s-au putut obține date despre facilitator/jucător.',
		ua: 'Не вдалося отримати дані фасилітатора/гравця.',
	},
	cpanelCouldNotGetUsers: {
		da: 'Kunne ikke hente brugere.',
		en: 'Could not get users.',
		se: 'Kunde inte få användare.',
		de: 'Benutzer konnten nicht abgerufen werden.',
		pl: 'Nie udało się uzyskać użytkowników.',
		ro: 'Nu s-au putut obține utilizatori.',
		ua: 'Не вдалося отримати користувачів.',
	},
	couldNotGetCompaniesCourses: {
		da: 'Kunne ikke hente firmaer og/eller kurser.',
		en: 'Could not get companies and/or courses.',
		se: 'Kunde inte få företag och/eller kurser.',
		de: 'Unternehmen und/oder Kurse konnten nicht abgerufen werden.',
		pl: 'Nie udało się znaleźć firm i/lub kursów.',
		ro: 'Nu s-au putut obține companii și/sau cursuri.',
		ua: 'Не вдалося отримати компанії та/або курси.',
	},
	couldNotGetCryptoKeys: {
		da: 'Kunne ikke hente krypteringsnøgler.',
		en: 'Could not get encryption keys.',
		se: 'Det gick inte att hämta krypteringsnycklar.',
		de: 'Verschlüsselungsschlüssel konnten nicht abgerufen werden.',
		pl: 'Nie udało się uzyskać kluczy szyfrujących.',
		ro: 'Nu s-au putut obține cheile de criptare.',
		ua: 'Не вдалося отримати ключі шифрування.',
	},
	createUserDataFailed: {
		da: 'Kunne ikke oprette brugerdata.',
		en: 'Could not create user data.',
		se: 'Kunde inte skapa användardata.',
		de: 'Benutzerdaten konnten nicht erstellt werden.',
		pl: 'Nie można utworzyć danych użytkownika.',
		ro: 'Nu s-au putut crea datele utilizatorului.',
		ua: 'Не вдалося створити дані користувача.',
	},


	/* Input errors */
	emptyField: {
		da: 'Ikke udfyldt',
		en: 'Empty field',
		se: 'Tomt fält',
		de: 'Leeres Feld',
		pl: 'Puste pole',
		ro: 'Puste stâlp',
		ua: 'Пусте полюс',
	},
	emptyFields: {
		da: 'Husk at udfylde alle felter.',
		en: 'Please fill out all fields.',
		se: 'Vänligen fyll i alla fält.',
		de: 'Bitte fülle alle Felder aus.',
		pl: 'Proszę wypełnić wszystkie pola.',
		ro: 'Vă rugăm să completați toate câmpurile.',
		ua: 'Будь ласка, заповніть усі поля.',
	},
	missingName: {
		da: `Navn mangler`,
		en: `Missing name`,
		se: `Saknar namn`,
		de: `Fehlender Name`,
		pl: `Brak nazwy`,
		ro: `Numele lipsește`,
		ua: `Відсутнє ім'я`,
	},
	invalidName: {
		da: `Ugyldigt navn`,
		en: `Invalid name`,
		se: `Ogiltigt namn`,
		de: `Ungültiger Name`,
		pl: `Błędna nazwa`,
		ro: `Nume invalid`,
		ua: `Недійсне ім'я`,
	},
	missingEmail: {
		da: 'E-mail mangler',
		en: 'Missing e-mail',
		se: 'E-post saknas',
		de: 'Fehlende E-Mail',
		pl: 'Brakujący email',
		ro: 'Lipsește e-mailul',
		ua: 'Відсутня електронна адреса',
	},
	invalidEmail: {
		da: 'Ugyldig e-mail',
		en: 'Invalid e-mail',
		se: 'Ogiltig e-postadress',
		de: 'Ungültige E-Mail',
		pl: 'Niepoprawny email',
		ro: 'E-mail invalid',
		ua: 'Недійсна електронна адреса',
	},
	couldNotGenerateUniqueId: {
		da: 'Kunne ikke generere unikt id',
		en: 'Could not generate unique id',
		se: 'Det gick inte att skapa ett unikt id',
		de: 'Es konnte keine eindeutige ID generiert werden',
		pl: 'Nie można wygenerować unikalnego identyfikatora',
		ro: 'Nu s-a putut genera ID unic',
		ua: 'Не вдалося створити унікальний ідентифікатор',
	},
	passwordsDoNotMatch: {
		da: 'De to passwords er ikke ens',
		en: 'The two passwords do not match',
		se: 'De två lösenorden stämmer inte överens',
		de: 'Die zwei Passwörter stimmen nicht überein',
		pl: 'Te dwa hasła nie pasują do siebie',
		ro: 'Cele două parole nu se potrivesc',
		ua: 'Два паролі не збігаються',
	},


	/* File reader errors */
	couldNotReadFile: {
		da: 'Filen kunne ikke læses. Tjek at den er af den rigtige type (%type%).',
		en: 'Could not read file. Check that it is of the correct type (%type%).',
		se: 'Kunde inte läsa filen. Kontrollera att den är av rätt typ (%type%).',
		de: 'Die Datei konnte nicht gelesen werden. Überprüfen Sie, ob sie vom richtigen Typ ist (%type%).',
		pl: 'Nie można odczytać pliku. Sprawdź, czy jest on prawidłowego typu (%type%).',
		ro: 'Nu s-a putut citi fișierul. Verificați dacă este de tipul corect (%type%).',
		ua: 'Не вдалося прочитати файл. Перевірте, чи має він правильний тип (%type%).',
	},
	noData: {
		da: 'Filen indeholdt ikke noget data',
		en: 'The file did not contain any data',
		se: 'Filen innehöll inga data',
		de: 'Die Datei enthielt keine Daten',
		pl: 'Plik nie zawierał żadnych danych',
		ro: 'Fișierul nu conținea date',
		ua: 'Файл не містив жодних даних',
	},
	noDataExcel: {
		da: 'Filen indeholdt ikke noget data. Bemærk at kun det første ark læses.',
		en: 'The file did not contain any data. Note that only the first sheet is read.',
		se: 'Filen innehöll inga data. Observera att endast det första arket läses.',
		de: 'Die Datei enthielt keine Daten. Beachten Sie, dass nur das erste Blatt gelesen wird.',
		pl: 'Plik nie zawierał żadnych danych. Należy zauważyć, że odczytywany jest tylko pierwszy arkusz.',
		ro: 'Fișierul nu conținea date. Rețineți că se citește doar prima foaie.',
		ua: 'Файл не містив жодних даних. Зверніть увагу, що читається лише перший аркуш.',
	},
	invalidColumnsExcelCsv: {
		da: 'Ugyldige kolonnenumre.',
		en: 'Invalid column numbers.',
		se: 'Ogiltiga kolumnnummer.',
		de: 'Ungültige Spaltennummern.',
		pl: 'Nieprawidłowe numery kolumn.',
		ro: 'Numerele coloanelor nevalide.',
		ua: 'Недійсні номери стовпців.',
	},
	noValidDataExcelCsv: {
		da: 'Filen indeholdt ikke noget gyldigt data i kolonne %col1% og %col2%. Bemærk at kun det første ark læses.',
		en: 'The file did not contain any valid data in column %col1% and %col2%. Note that only the first sheet is read.',
		se: 'Filen innehöll inga giltiga data i kolumn %col1% och %col2%. Observera att endast det första arket läses.',
		de: 'Die Datei enthielt keine gültigen Daten in den Spalten %col1% und %col2%. Beachten Sie, dass nur das erste Blatt gelesen wird.',
		pl: 'Plik nie zawiera żadnych prawidłowych danych w kolumnach %col1% i %col2%. Należy pamiętać, że odczytywany jest tylko pierwszy arkusz.',
		ro: 'Fișierul nu conținea date valide în coloana %col1% și %col2%. Rețineți că se citește doar prima foaie.',
		ua: 'Файл не містив дійсних даних у стовпцях %col1% і %col2%. Зверніть увагу, що читається лише перший аркуш.',
	},
	couldNotParseDatafiles: {
		da: 'Kunne ikke læse/parse json-filerne.',
		en: 'Could not read/parse the json files.',
		se: 'Kunde inte läsa/tolka json-filerna.',
		de: 'Die JSON-Dateien konnten nicht gelesen/analysiert werden.',
		pl: 'Nie można odczytać/przetworzyć plików json.',
		ro: 'Nu s-au putut citi/analiza fișierele json.',
		ua: 'Не вдалося прочитати/розібрати файли json.',
	},
	couldNotParseAudioFiles: {
		da: 'Kunne ikke læse/parse lydfilerne.',
		en: 'Could not read/parse the audio files.',
		se: 'Kunde inte läsa/tolka ljudfilerna.',
		de: 'Die Audiodateien konnten nicht gelesen/analysiert werden.',
		pl: 'Nie można odczytać/przetworzyć plików audio.',
		ro: 'Nu s-au putut citi/analiza fișierele audio.',
		ua: 'Не вдалося прочитати/розібрати аудіофайли.',
	},
	couldNotParseImageFiles: {
		da: 'Kunne ikke læse/parse billedfilerne.',
		en: 'Could not read/parse the image files.',
		se: 'Kunde inte läsa/tolka bildfilerna.',
		de: 'Die Bilddateien konnten nicht gelesen/analysiert werden.',
		pl: 'Nie można odczytać/przetworzyć plików graficznych.',
		ro: 'Nu s-au putut citi/analiza fișierele imagine.',
		ua: 'Не вдалося прочитати/розібрати файли зображень.',
	},
	couldNotFindExpectedFiles: {
		da: 'Kunne ikke finde forventede filer: %files%.',
		en: 'Could not find expected files: %files%.',
		se: 'Kunde inte hitta förväntade filer: %files%.',
		de: 'Die erwarteten Dateien konnten nicht gefunden werden: %files%.',
		pl: 'Nie można odnaleźć oczekiwanych plików: %files%.',
		ro: 'Nu s-au putut găsi fișierele așteptate: %fișiere%.',
		ua: 'Не вдалося знайти очікувані файли: %files%.',
	},

	/* Login page errors */
	unknownLoginType: {
		da: 'Ukendt login type: ',
		en: 'Unknown login type: ',	
		se: 'Okänd inloggningstyp: ',
		de: 'Unbekannter Login-Typ: ',
		pl: 'Nieznany typ logowania: ',
		ro: 'Nieznany typ logowania: ',
		ua: 'Невідомий тип входу: ',
	},
};

export {
	errorUiTexts
};