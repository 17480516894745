import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {apiHelper} from 'helpers/api-helper';
import {getText, renderMarkdown} from 'helpers/text-helper';
import {getAvailableLanguagesData } from 'helpers/language-helper';
import {checkIfValidEmailFormat} from 'helpers/format-helper';
import {bookDemoData} from 'data/landing-page-data';
import CGLLogo from 'components/ui/cgl-logo/cgl-logo';
import PlatformTitle from 'components/ui/platform-title/platform-title';
import Select from 'components/ui/select/select';
import Button from 'components/ui/button/button';
import Input from 'components/ui/input/input';
import './book-demo.scss';

const BookDemo = ({languageId, deviceInfo, setLanguageId}) => {
	/* Available languages */
	const availableLanguagesData = getAvailableLanguagesData('default', null, null, true);

	/* Input fields */
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [company, setCompany] = useState(null);
	const [phone, setPhone] = useState(null);

	/* Loading status */
	const [isLoading, setIsLoading] = useState(false);

	/* Booking status */
	const [mailIsSent, setMailIsSent] = useState(false);

	/* Error msg */
	const [errMsg, setErrMsg] = useState(null);

	/* Update input */
	const updateInput = (e) => {
		if (e.target.name === 'name') {
			setName(e.target.value);
		}

		if (e.target.name === 'email') {
			setEmail(e.target.value);	
		}

		if (e.target.name === 'company') {
			setCompany(e.target.value);
		}

		if (e.target.name === 'phone') {
			setPhone(e.target.value);
		}
	};
	
	/**
	 * Handle book demo (i.e. send e-mail to us)
	 */
	const handleBookDemo = () => {
		if (isLoading) return;

		setIsLoading(true);
		setErrMsg(null);

		/* Check input fields */
		let newErrMsg = null;
		if (
			!name || name === '' ||
			!email || email === '' ||
			!company || company === '' ||
			!phone || phone === ''
		) {
			newErrMsg = getText('error_emptyFields', languageId);
		} else {
			if (!checkIfValidEmailFormat(email)) {
				newErrMsg = getText('error_invalidEmail', languageId);
			}
		}

		if (newErrMsg) {
			/* Invalid input fields */
			setErrMsg(newErrMsg);
			setIsLoading(false);
		} else {
			/* Input fields ok, send e-mail */
			apiHelper('contact/book-demo', {
				name, email, company, phone,
			}).then(
				(response) => {
					if (response.status === 'success') {
						/* Success: e-mail with info sent to us */
						setMailIsSent(true);
						setIsLoading(false);
					} else {
						/* Error: server error */
						console.error(response);
						setErrMsg(getText('error_serverError', languageId));
						setIsLoading(false);
					}
				},
				(rejection) => {
					/* Error: server error */
					console.error(rejection);
					setErrMsg(getText('error_serverError', languageId));
					setIsLoading(false);
				}
			);
		}
	};


	return (
		<div className={'BookDemo ' + deviceInfo.orientation}>
			{/* Header */}
			<div className='BookDemo-header'>
				{/* CGL logo, platform title */}
				<CGLLogo languageId={languageId}/>
				<PlatformTitle languageId={languageId} />

				{/* Selected language */}
				{availableLanguagesData && <div className='BookDemo-language'>
					<Select 
						useShortTitleForSelected={true}
						languageId={languageId}
						type="language"
						selectedId={languageId}
						options={availableLanguagesData}
						onSelect={setLanguageId}
					/>
				</div>}

				{/* Page title */}
				<div className='BookDemo-subtitle'>
					<span>{getText(bookDemoData.title, languageId)}</span>
				</div>
			</div>

			{/* Content */}
			<div className='BookDemo-content'>
				{/* Phone number */}
				<div className='BookDemo-phoneButton'>
					<span>{getText(bookDemoData.telephoneShort, languageId) + ' ' + appConfig.phoneNumber}</span>
				</div>

				{/* Text */}
				<div className='BookDemo-guideText'>
					<span>{getText(bookDemoData.instructions, languageId)}</span>
				</div>

				<div className='BookDemo-contentRow'>
					{/* Helping points */}
					<div className='BookDemo-contentText'>
						<div className='BookDemo-contentTextHeader'>
							<span>{getText(bookDemoData.textPointsHeader, languageId)}</span>
						</div>
						<div className='BookDemo-contentTextPoints'>
							{bookDemoData.textPoints.map((point, index) => {
								return (
									<div key={index} className='BookDemo-contentPoint'>
										<div className='BookDemo-pointImage'/>
										<div className='BookDemo-pointText'>
											<span>{point[languageId]}</span>
										</div>
									</div>
								);
							})}
						</div>
					</div>

					{/* Contact form */}
					{!mailIsSent && <div className='BookDemo-inputWindow'>
						{/* Input fields */}
						{bookDemoData.inputFields.map((inputField) => {
							let value = '';
							if (inputField.id === 'name') value = name;
							if (inputField.id === 'email') value = email;
							if (inputField.id === 'company') value = company;
							if (inputField.id === 'phone') value = phone;

							return (
								<div key={inputField.id} className='BookDemo-inputWrapper'>
									<div className='BookDemo-inputHeader'>
										<span>{getText(inputField.text, languageId)}</span>
									</div>
									<div className='BookDemo-input'>
										<Input
											classes={['fullHeight', 'fullWidth', 'login', 'selectResponseGreen']}
											name={inputField.id}
											type="text"
											value={value}
											onChange={updateInput}
										/>
									</div>
								</div>
							);
						})}
						{/* Book button */}
						<div className='BookDemo-bookButton'>
							<Button
								isLoading={isLoading}
								classes={
									['rounded', 'facilitator', 'green', 'fullHeight', 'fullWidth']
								}
								text={getText(bookDemoData.inputButton, languageId).toUpperCase()}
								onClick={() => {handleBookDemo();}}
							/>
						</div>
						{/* Error message */}
						<div className='BookDemo-errMsg'>
							<span>{errMsg}</span>
						</div>
					</div>}

					{/* Contact form result */}
					{mailIsSent && <div className="BookDemo-resultWindow">
						<div className="BookDemo-resultText">
							{renderMarkdown(getText(bookDemoData.feedbackText, languageId))}
						</div>
					</div>}
				</div>
			</div>
		</div>
	);
};

BookDemo.propTypes = {
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	setLanguageId: PropTypes.func.isRequired,
};

export default BookDemo;