import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {getAvailableLanguagesData} from 'helpers/language-helper';
import {getText} from 'helpers/text-helper';
import {landingPageGalleryData, bookDemoData} from 'data/landing-page-data';
import Button from 'components/ui/button/button';
import Select from 'components/ui/select/select';
import PlatformTitle from 'components/ui/platform-title/platform-title';
import CGLLogo from 'components/ui/cgl-logo/cgl-logo';
import './landing-page.scss';

const LandingPage = ({languageId, deviceInfo, setLanguageId}) => {
	/* Router navigation */
	const navigate = useNavigate();
	
	/* Interval for gallery automatic cycle */
	const interval = useRef(null);

	/* Available languages */
	const availableLanguagesData = getAvailableLanguagesData('default', null, null, true);

	const [currentGalleryDataIndex, setCurrentGalleryDataIndex] = useState(0);

	useEffect(() => {
		interval.current = setInterval(() => {
			setCurrentGalleryDataIndex((prevState) => {
				return (prevState + 1) % landingPageGalleryData.length;
			});
		}, 10000);
		
		return () => {
			if (interval && interval.current) {
				clearInterval(interval.current);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Increments gallery in given direction and loops
	 * @param {number} direction 
	 */
	const incrementGallery = (direction) => {
		let newIndex = currentGalleryDataIndex + direction;

		if (newIndex < 0) {
			newIndex = landingPageGalleryData.length - 1;
		} else if (newIndex > landingPageGalleryData.length - 1) {
			newIndex = 0;
		}

		setCurrentGalleryDataIndex(newIndex);
	};

	return (
		<div className={'LandingPage ' + deviceInfo.orientation}>
			<div className='LandingPage-wrapper'>
				<div className='LandingPage-header'>
					<CGLLogo languageId={languageId}/>
					<PlatformTitle languageId={languageId} />
					<div className='LandingPage-subtitle'/>

					{availableLanguagesData &&
						<div className='LandingPage-language'>
							<Select 
								useShortTitleForSelected={true}
								languageId={languageId}
								type="language"
								selectedId={languageId}
								options={availableLanguagesData}
								onSelect={setLanguageId}
							/>
						</div>
					}
				</div>

				<div className='LandingPage-content'>
					<div className='LandingPage-bookDemoButton'>
						<Button 
							classes={
								['rounded', 'facilitator', 'responseWhite', 'green', 'fullHeight', 'fullWidth']
							}
							text={getText(bookDemoData.title, languageId).toUpperCase()}
							onClick={() => {navigate('/bookdemo');}}
						/>
					</div>

					<div className='LandingPage-galleryWrapper'>
						{landingPageGalleryData.map((data, index) => {
							const isCurrent = currentGalleryDataIndex === index;

							return (
								<div key={index} className={
									'LandingPage-galleryItem ' +
									(isCurrent ? ' isCurrent' : ' notCurrent')
								}>
									<div className='LandingPage-galleryTextWrapper'>
										<div className='LandingPage-galleryTextHeader'>
											<span>
												{landingPageGalleryData[index].textData.title[languageId]}
											</span>
										</div>
										<div className='LandingPage-galleryText'>
											<span>
												{landingPageGalleryData[index].textData.text[languageId]}
											</span>
										</div>
									</div>
									<div className={
										'LandingPage-galleryImage ' +
										data.imageId
									}/>
								</div>
							);
						})}
					</div>

					<div className='LandingPage-galleryDots'>
						{landingPageGalleryData.map((_, index) => {
							return (
								<div key={index} className={
									'LandingPage-galleryDot ' + (currentGalleryDataIndex === index ? 'current' : '')
								}/>
							);
						})}
					</div>

					<div className='LandingPage-arrowLeft' onClick={() => {incrementGallery(-1);}}/>
					<div className='LandingPage-arrowRight' onClick={() => {incrementGallery(1);}}/>
				</div>
			</div>
		</div>
	);
};

LandingPage.propTypes = {
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	setLanguageId: PropTypes.func.isRequired,
};

export default LandingPage;
